const serverConfig = {
	// baseURL: "http://127.0.0.1:9999/",
	baseURLWs: "wss://lriscr.cn:9999/websocket/",
	// baseURL: "https://103zeai.cn:9999/",
	// 咿昂洋
	baseURL: "https://lriscr.cn:9999/",
	//长大成人
	// baseURL: "https://dachuan2107.com:9999/",
	userTokenAuthoriaztipn: true //是否开启token的验证
}

export default serverConfig;